<template>
  <div class="table-header-viewerx align-center">
    <slot name="prepends" />
    <v-text-field
      dense
      v-if="hasSearch"
      outlined
      clearable
      height="35"
      clear-icon="mdi-close-circle-outline"
      solo
      style="max-width: 200px"
      flat
      placeholder="Search for..."
      append-icon="search"
      v-model="keyword"
      hide-details="auto"
    />
    <slot name="center" />
    <v-tooltip top v-if="hasSortButton">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            v-bind="attrs"
            color="primary"
            class="mx-1"
            v-on="on"
            depressed
            @click="$emit('click-sort-by')"
          >
            <v-icon :color="hover ? 'green' : 'white'"> mdi-sort </v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>Sort by</span>
    </v-tooltip>
    <v-tooltip top v-if="hasAddButton">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="primary"
          class="ml-1"
          v-on="on"
          depressed
          active
          @click="$emit('click')"
        >
          <v-icon color="white"> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Create new</span>
    </v-tooltip>
    <v-btn-toggle v-model="activeView" mandatory dense class="mx-1">
      <template v-if="hasViewButton">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="activeView === 'list' ? '#223A6B' : ''"
              value="list"
              outlined
              active-class="primary"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="activeView === 'list' ? 'white' : 'primary'">
                mdi-view-list
              </v-icon>
            </v-btn>
          </template>
          <span>List view</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="activeView === 'grid' ? '#223A6B' : ''"
              active-class="primary"
              value="grid"
              depressed
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :color="activeView === 'grid' ? 'white' : 'primary'">
                mdi-view-grid
              </v-icon>
            </v-btn>
          </template>
          <span>Grid view</span>
        </v-tooltip>
      </template>
    </v-btn-toggle>
    <slot name="form-btn" />
  </div>
</template>
<script>
export default {
  name: 'TableHeader',
  props: {
    value: { type: [String, Number], default: '' },
    hasSearch: { type: Boolean, default: false },
    hasAddButton: { type: Boolean, default: true },
    hasViewButton: { type: Boolean, default: true },
    hasSortButton: { type: Boolean, default: false }
  },
  data() {
    return {
      fab: true,
      keyword: '',
      debounce: null
    }
  },
  watch: {
    value(val) {
      this.keyword = val
    },
    keyword(val) {
      this.$emit('input', val)
    }
  },
  computed: {
    activeView: {
      get() {
        return this.$store.getters.view_type || 'list'
      },
      set(val) {
        this.$store.commit('set_view_type', val)
        this.$emit(`click-${val}-view`)
      }
    }
  }
}
</script>
<style lang="scss">
.table-header-viewerx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .v-btn {
    height: 40px !important;
  }
  .v-input {
    height: 40px !important;
  }
}
</style>
