var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-header-viewerx align-center"},[_vm._t("prepends"),(_vm.hasSearch)?_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"dense":"","outlined":"","clearable":"","height":"35","clear-icon":"mdi-close-circle-outline","solo":"","flat":"","placeholder":"Search for...","append-icon":"search","hide-details":"auto"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}):_vm._e(),_vm._t("center"),(_vm.hasSortButton)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('click-sort-by')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":hover ? 'green' : 'white'}},[_vm._v(" mdi-sort ")])],1)]}}],null,true)})]}}],null,false,4169171600)},[_c('span',[_vm._v("Sort by")])]):_vm._e(),(_vm.hasAddButton)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","depressed":"","active":""},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,2232750649)},[_c('span',[_vm._v("Create new")])]):_vm._e(),_c('v-btn-toggle',{staticClass:"mx-1",attrs:{"mandatory":"","dense":""},model:{value:(_vm.activeView),callback:function ($$v) {_vm.activeView=$$v},expression:"activeView"}},[(_vm.hasViewButton)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.activeView === 'list' ? '#223A6B' : '',"value":"list","outlined":"","active-class":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.activeView === 'list' ? 'white' : 'primary'}},[_vm._v(" mdi-view-list ")])],1)]}}],null,false,3177474311)},[_c('span',[_vm._v("List view")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.activeView === 'grid' ? '#223A6B' : '',"active-class":"primary","value":"grid","depressed":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.activeView === 'grid' ? 'white' : 'primary'}},[_vm._v(" mdi-view-grid ")])],1)]}}],null,false,3904086663)},[_c('span',[_vm._v("Grid view")])])]:_vm._e()],2),_vm._t("form-btn")],2)}
var staticRenderFns = []

export { render, staticRenderFns }